.main {
  position: relative;
  overflow: hidden;
  height: 30vh;
  width: 100%;
  z-index: -1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}

.content {
  height: 100%;
  width: 100%;
}

.blob-1 {
  width: 80px;
  height: 50px;
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  z-index: -100;
  top: 20%;
  right: 5%;
  animation: wave 10s ease-in-out infinite;
  border-radius: 100% 15% 45% 96% / 100% 0% 93% 43%;
}

.blob-2 {
  width: 80px;
  height: 100px;
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  z-index: -100;
  top: 30%;
  left: 5%;
  animation: wave 10s ease-in-out infinite;
  border-radius: 68% 61% 38% 95% / 37% 90% 23% 74%;
}

.blob-3 {
  width: 80px;
  height: 100px;
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  z-index: -100;
  top: -5%;
  left: 35%;
  animation: wave 10s ease-in-out infinite;
  border-radius: 36% 100% 10% 100% / 68% 72% 10% 73%;
}