@import url('https://fonts.googleapis.com/css2?family=Gentium+Plus&display=swap');

.art-pieces-header {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
}

.header-text {
    font-family: 'Gentium Plus', serif;
    font-size: 3rem;
    font-weight: 400;
}

.header-sub-text {
    margin-top: 0.5rem;
    font-size: 1rem;
}

.category {
    font-weight: 400;
    cursor: pointer;
    padding-bottom: 5px;
    border-bottom: 1px solid whitesmoke;
}

.empty {
    height: 30vh;
    display: flex;
    justify-content: center;
}