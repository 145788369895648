.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(180px, auto);
  gap: 10px;
  grid-template-areas:
    "image1 image2 image3"
    "image1 image2 image3"
    "image1 image2 image3"
    "image4 image2 image5"
    "image4 image6 image5"
    "image4 image6 image5";
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-auto-rows: 500px;
    grid-template-areas:
      "image1"
      "image2"
      "image3"
      "image4"
      "image5"
      "image6";
  }
}

@media (min-width: 481px) and (max-width: 868px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    grid-template-areas:
      "image1 image2"
      "image1 image2"
      "image3 image2"
      "image3 image2"
      "image4 image5"
      "image4 image5"
      "image4 image5"
      "image4 image5";
  }
}

.grid-item-1 {
  grid-area: image1;
}

.grid-item-2 {
  grid-area: image2;
}

.grid-item-3 {
  grid-area: image3;
}

.grid-item-4 {
  grid-area: image4;
}

.grid-item-5 {
  grid-area: image5;
}

/* .grid-item-6 {
  grid-area: image6;
} */

.image-item {
  width: 100%;
  height: auto;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.member-card {
  background-color: whitesmoke;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0.5rem 1rem;
}