.header {
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    font-size: 3rem;
    font-weight: 400;
}

.price {
    font-size: 1.2rem;
    opacity: 0.5;
    font-weight: normal;
}

input[type=number] {
    width: 60px;
}
